import React from 'react'
import Layout from '../components/layout'
import HeaderSubsite from '../components/headersubsite'
import ConsultingAppointment from '../components/consultingappointment'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'
import ImageText from '../components/imagetext'

import BackgroundImageTwo from '../images/shutterstock_1090445729.jpg'
import Srd4 from '../images/srd4.jpg'
import Beratung from '../images/beratung.jpg'
import Beratung2 from '../images/beratung2.jpg'

const HonorarPage = () => (
  <Layout>
    <HeaderSubsite
      headline="Unsere Honorar"
      subline="Was darf ein guter Steuerberater kosten?"
      bg={Srd4}
    />
    <ImageText
      bg={Beratung}
      subline=" "
      headline="Was darf ein guter Steuerberater kosten?"
    >
      <p>
        <br />
        Grundsätzlich gilt: Der Rahmen für die Vergütung von Steuerberatern wird
        in Deutschland durch die sogenannte Steuerberatervergütungsverordnung
        (StBVV) vorgegeben. Hierbei handelt es sich um ein Vergütungssystem, das
        mehrere Faktoren bei der Berechnung des Honorars berücksichtigt. Die
        Höhe der steuerrelevanten Summe („Wertgebühr“) fließt in diesen
        „Honorar-Schlüssel“ ebenso mit ein wie der Zeitaufwand sowie nicht
        zuletzt der Schwierigkeitsgrad des jeweiligen Falles.
        <br />
        <br />
        Alternativ gibt es auch die Möglichkeit einer Pauschalvergütung nach §
        14 StBVV, sofern dies von beiden Seiten gewünscht wird. Die Vereinbarung
        für eine Pauschalvergütung ist dabei schriftlich und für einen Zeitraum
        von mindestens einem Jahr zu treffen. Gerne geben wir Ihnen nach dem
        Erstberatungsgespräch eine Einschätzung des Kostenrahmens.
        <br />
        <br />
        Nur im Falle, dass nach der <strong>Erstberatung</strong> kein
        Mandatsverhältnis entsteht, würden wir Ihnen dieses Beratungsgespräch in
        Rechnung stellen. Gemäß Steuerberatervergütungsverordnung darf die
        Erstberatung maximal 190 Euro netto betragen.
        <br />
        <br />
        Eine Gründungserstberatung, zum Beispiel die Prüfung eines umfangreichen
        Businessplanes inklusive Tragfähigkeitsprüfung, Rentabilitätsvorschau
        sowie Investitions- und Finanzierungsplan, aber auch komplexe steuer-
        und betriebswirtschaftliche Anliegen kalkulieren wir Ihnen abweichend
        von der Erstberatung nach individuellem Aufwand. Natürlich ist
        unabhängig davon immer ein erstes Kennlerngespräch möglich und sinnvoll.
        <br />
      </p>
    </ImageText>
    <ConsultingAppointment
      title={
        <>
          {`Rufen Sie uns an oder senden Sie uns eine Email –`}
          <br />
          {` wir freuen uns auf Sie.`}
        </>
      }
      cta="Termin vereinbaren"
      bgcolor="#d3a04e"
      color="#fff"
    />
    <ImageText
      bg={Beratung2}
      headline="Liebe auf den ersten Blick?"
      subline=""
      direction="row-reverse"
    >
      <p>
        <br />
        Die Erstberatung zwischen Steuerberater und einem potenziellem Mandanten
        bzw. Mandantin beginnt dort, wo das Internet an seine Grenzen stößt:
        beim vertrauensvollen Kennenlernen in unserer Kanzlei. In einem
        persönlichen Gespräch besprechen wir Ihr individuelles Anliegen. Wir
        klären Fragen, reden über Ihre Ziele und zeigen dabei vielleicht auch
        schon erste Lösungsansätze auf.
        <br />
        <br />
        Diese erste „Analyse“ kann je nach Anliegen 30 oder 40 Minuten Zeit in
        Anspruch nehmen. Bei komplexen betriebswirtschaftlichen Fragestellungen
        kann sie aber auch vier oder fünf Stunden dauern – zum Beispiel dann,
        wenn Sie uns bereits im Vorfeld des Erstgesprächs umfangreiche
        Unterlagen mit der Bitte um Prüfung und Einschätzung haben zukommen
        lassen. Sie sind Unternehmensgründer oder Gesellschafter eines
        Start-Ups? Dann haben Sie im Hinblick auf das Erstgespräch vielleicht
        schon ganz konkrete Fragestellungen. Zum Beispiel ob und wann ein
        Investitionsabzugsbetrag (IAB) im Hinblick auf zukünftige Investitionen
        in Ihrem Unternehmen sinnvoll ist – und welche Vorzüge sich daraus für
        Ihren unternehmerischen Spielraum ergeben.
        <br />
        <br />
        Unser Tipp: Lassen Sie bei diesem Erstgespräch nicht nur die Fakten
        sprechen, sondern auch Ihr Bauchgefühl. Entsteht ein Dialog auf
        Augenhöhe? Stimmt die Chemie? Fühle ich mich mit meinem Anliegen
        verstanden? Ist die Atmosphäre konzentriert und professionell, aber auch
        menschlich und unverkrampft? Wenn Sie all diese Fragen mit einem ganz
        entspannten Lächeln beantworten können, stehen die Sterne für eine
        langfristige und partnerschaftliche Zusammenarbeit ausgesprochen gut.
        <br />
      </p>
    </ImageText>

    <Footer />
    <SubFooter />
  </Layout>
)

export default HonorarPage
